* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased; }

html, body, {
  height: 100%;
  // min-height: 100% !important
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  -webkit-overflow-scrolling: touch; }

#root {
  min-height: 100%;
  height: 100%;
  flex-grow: 1; }

.full-height {
  height: 100%;
  min-height: 100%; }

.game {
  min-height: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;

  &::after {
    content: '';
    background-color: rgba(0,0,0,0.5);
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 800ms; }

  &--in-play::after {
    opacity: 1; }

  &--stage {

    &--playing, &--final {
      height: initial; } }

  &__overlay {
    position: fixed;
    height: 100vh;
    width: 100%;
    background-color: rgba(0,0,0,0.4);
    left: 0;
    top: 0; }

  &__intro-video {
    position: fixed;
    bottom: 0;
    left: 0;
    min-height: 100%;
    min-width: 100%;
    z-index: 1; }

  &__screen {
    z-index: 2;
    position: relative;
    height: 100%; } }

@media screen and (max-width: $mobileBreakpoint2 + 'px') {
  .game {

    &__info-toggle {
      top: 10px;
      right: 10px; } } }
